import { Injectable } from '@angular/core';
import algoliasearch from 'algoliasearch';

import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AlgoliaService {
    public index: any;
    public indexCategory: any;

    constructor() {
        const client = algoliasearch(
            environment.ALGOLIA.appId,
            environment.ALGOLIA.apiKey
        );

        this.index = client.initIndex(environment.ALGOLIA.indexName);
        this.indexCategory = client.initIndex(
            environment.ALGOLIA.indexNameCategory
        );
    }
}
